<template lang="pug">
div(v-bar)
  .homepage.page(v-scroll.self="onScroll")
    nav-header(:offset="offset")
    .hero
      img(src="/homepage/total_bg.png").background
      parallax.foreground(direction="down" :speedFactor="0.1" :offset="offset" src="/homepage/foreground.png")
      .skyline-measure
        img(src="/homepage/skyline_measure.png").skyline
        parallax(direction="down" :offset="offset" src="/homepage/cloud.png").cloud
        parallax(direction="down" :offset="offset" src="/homepage/cloud_2.png").cloud2
        parallax(direction="down" :offset="offset" src="/homepage/cloud_3.png").cloud3
        parallax(direction="down" :offset="offset" src="/homepage/cloud_4.png").cloud4
        .backdrop-clip
          parallax(direction="down" :speedFactor="0.05" :offset="offset" src="/homepage/mountains.png").mountains
        .bottom-line
          parallax.train(:speedFactor="0.01" :offset="offset" src="/homepage/train.png")
          parallax.town_traintrack(:speedFactor="0.01" src="/homepage/town_traintrack.png")
          parallax.forest(:speedFactor="0.01" :offset="offset" src="/homepage/forest.png")
      .slogan-container.px-16
        h2.text-subtitle-1.text-sm-h6.text-lg-h4.text-xl-h2.font-weight-bold.primary--text Connecting people
        .swimlane.pb-lg-2.pb-1.ml-md-16.pl-xl-16
          transition(name="scroll-in" mode="out-in")
            h2.text-subtitle-1.text-sm-h6.text-lg-h4.text-xl-h2.font-weight-bold.accent--text(v-for="(word, index) in slogans" :key="index" v-if="wordIndex === index") {{ word }}
    .content.pt-16
      img.foredrop(src="/homepage/foredrop.png")
      v-row.flex-column.px-4
        v-col(cols="12").mb-lg-12.d-none.d-md-flex
        content-block(v-for="(item, i) in contents" :key="`c-${i}`" :heading="item.heading" :text="item.text" :image="item.image" :flip="i % 2 !== 0")
        v-col(cols="12" md="10" lg="8" xl="6" :offset-xl="3" :offset-lg="2" :offset-md="1").my-8.my-lg-12
          v-divider(dark)
        v-col(cols="12" md="8" lg="6" xl="4" :offset-xl="4" :offset-lg="3" :offset-md="2").mt-8.mt-lg-12.d-flex.align-center.justify-center.flex-column.text-center
          h4.text-h3.font-weight-bold.accent--text Consider donating
          p.text-subtitle-1.white--text To keep this website up and running and ad-free I rely on small donations. With the button below you can support this website for only &euro;2 (one-time or by a monthly subscription). More on this <nuxt-link to="/faq#donation" color="accent" class="accent--text text-decoration-none">here</nuxt-link>.
        v-col(cols="12").d-flex.align-center.justify-center.mb-8.mb-lg-12
          buy-coffee-btn.px-16.my-8.mx-4
        v-col(cols="12" md="10" lg="8" xl="6" :offset-xl="3" :offset-lg="2" :offset-md="1").my-8.my-lg-12
          v-divider(dark)
        v-col(cols="12").mt-8.mt-lg-12.d-flex.align-center.justify-center
          h4.text-h3.font-weight-bold.accent--text Want to join?
        v-col(cols="12").d-flex.align-center.justify-center.mb-8.mb-lg-12
          v-btn(x-large color="accent" nuxt to="/register").px-16.font-weight-bold.my-8.mx-4 Register
          v-btn(x-large color="accent" nuxt to="/login").px-16.font-weight-bold.my-8.mx-4 Login
      footer.py-4.py-md-8.d-flex.flex-column.align-center.mt-8.mt-lg-12
        span.text-overline.white--text.secondary-opacity copyright &copy; 2020 <a href="https://svenwesterlaken.com" class="accent--text text-decoration-none">dots&amp;dice</a>
</template>

<script>
import { shuffle } from 'lodash'
import Parallax from '~/components/Parallax.vue'
import NavHeader from '~/components/NavHeader.vue'
import ContentBlock from '~/components/ContentBlock.vue'
import BuyCoffeeBtn from '~/components/BuyCoffeeBtn'
import Logo from '~/assets/svg/logotext_th.svg?inline'

export default {
  components: { Parallax, NavHeader, ContentBlock, Logo, BuyCoffeeBtn },
  head () {
    return this.$seo({
      title: 'Connecting people around the globe'
    })
  },
  data () {
    return {
      wordIndex: 0,
      words: [
        'based on memories',
        'to create ideas for the future',
        'to plan a roadtrip'
      ],
      offset: 0,
      contents: [
        {
          heading: 'Know where your friends come from',
          text: 'Want to plan a vacation but forgot which friends live where? Meetr is there to show you, even if your friend lives at two different places from time to time or moved somewhere else. That is, if they updated their information ofcourse.',
          image: '/ill/world.png'
        },
        {
          heading: 'Only share your location with friends',
          text: 'Meetr is divided in rooms/maps - or whatever you want to call them. Your location will only be shared with people in the same map. It is possible to join multiple rooms.',
          image: '/ill/friendspage.png'
        },
        {
          heading: 'Connect with people',
          text: 'Send a message to people without needing their phonenumber or email beforehand. You can find their preferred communication information by opening up their location.',
          image: '/ill/mailbox.png'
        }
      ]
    }
  },
  computed: {
    slogans () {
      return shuffle(this.words)
    }
  },
  mounted () {
    setInterval(this.updateWordIndex, 5000)
  },
  methods: {
    updateWordIndex () {
      this.wordIndex += this.wordIndex < this.words.length - 1 ? 1 : (this.words.length - 1) * -1
    },
    onScroll (e) {
      this.offset = e.target.scrollTop
    }
  }
}
</script>

<style lang="sass">
.homepage
  .content
    width: 100%
    display: block
    position: relative
    z-index: 1
    background: #16215C

    .foredrop
      position: absolute
      bottom: 99.5%
      left: 0
      width: 100%

    .home-logo
      height: 150px
      max-width: 90vw

      g, rect, path
        fill: #E7755F

    footer
      background: #1B2871

.secondary-opacity
  opacity: 0.73

.hero
  width: 100vw
  max-height: 100vh
  min-height: 56.25vw
  max-width: 100vw
  overflow: hidden
  position: relative
  display: block
  z-index: 0

  .swimlane
    height: 3.75rem
    max-height: 3.75rem
    overflow: hidden
    box-sizing: content-box

  .slogan-container
    display: block
    position: absolute
    bottom: 58%
    left: 5%
    z-index: 1

    @media screen and (max-width: 959px)
      bottom: 55%

    @media screen and (max-width: 759px)
      bottom: 50%
      left: 32px
      padding-left: 0 !important

    @media screen and (max-width: 559px)
      bottom: 42%

    @media screen and (max-width: 429px)
      bottom: 35%

    @media screen and (max-width: 389px)
      display: none

  .skyline-measure
    position: absolute
    width: 100%
    height: auto
    top: 0
    left: 0
    z-index: 1

    .bottom-line
      top: 100%
      width: 100%
      position: absolute

  .backdrop-clip
    position: absolute
    height: 100%
    width: 100%
    top: 0
    left: 0
    overflow: hidden

  .skyline
    position: relative
    opacity: 0
    width: calc(100% + 4px)
    left: -4px
    top: 0
    z-index: 1

  .background
    position: relative
    width: calc(100vw + 4px)
    left: -4px
    z-index: 0

  .town_traintrack
    position: absolute
    width: 120vw
    top: -8.5vw
    left: -10px

  .forest
    position: absolute
    top: -8.2vw
    right: -7vw
    width: 50vw

  .train
    position: absolute
    width: 4.2%
    top: -0.55vw
    animation-duration: 8s
    animation-fill-mode: forwards
    animation-iteration-count: infinite
    animation-timing-function: linear
    animation-name: drive

  .mountains
    position: absolute
    right: 0
    bottom: -80px
    width: 50%

  .foreground
    position: absolute
    bottom: 0
    left: 0
    z-index: 2
    width: 100%
    height: auto

  .cloud, .cloud2, .cloud3, .cloud4
    position: absolute
    width: 15%

  .cloud
    bottom: 40%
    right: 20%

  .cloud2
    bottom: 60%
    right: 25%

  .cloud3
    bottom: 70%
    left: -5px

  .cloud4
    bottom: 75%
    left: 25%

@keyframes drive
  0%
    left: 15%

  100%
    left: 60%

.scroll-in-enter-active, .scroll-in-leave-active
  transform: translateY(0rem)
  transition: all 600ms cubic-bezier(0.76, 0, 0.24, 1)

.scroll-in-enter
  transform: translateY(4rem)

.scroll-in-leave-to
  transform: translateY(-4rem)
</style>
