<template lang="pug">
v-btn(target="_blank" :href="href" color="accent" x-large).font-weight-bold 🍺 {{ text }}
</template>

<script>
export default {
  data () {
    return {
      text: 'Buy Me a Beer',
      href: 'https://buymeacoffee.com/svenwstrl'
    }
  }
}
</script>
