<template lang="pug">
  component.parallex-item(v-bind="$attrs" v-on="$listeners" ref="parallax" :is="tag")
    slot
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    parallax: {
      default: true,
      type: Boolean
    },
    speedFactor: {
      default: 0.15,
      type: Number
    },
    breakpoint: {
      default: '(min-width: 968px)',
      type: String
    },
    direction: {
      type: String,
      default: 'up'
    },
    tag: {
      type: String,
      default: 'img'
    },
    offset: {
      type: Number,
      default: undefined
    }
  },
  data () {
    return {
      el: null,
      mediaQuery: null
    }
  },
  computed: {
    directionValue () {
      return this.direction === 'down' ? +1 : -1
    }
  },
  watch: {
    offset () {
      window.requestAnimationFrame(() => {
        if (this.isInView(this.$refs.parallax) && this.mediaQuery.matches) {
          this.animateElement()
        } else if (!this.mediaQuery.matches) {
          // Reset if user resized the window while it was scrolled down
          this.el.style.transform = `translate3d(0, 0 ,0)`
        }
      })
    }
  },
  mounted () {
    this.el = this.$refs.parallax
    this.mediaQuery = window.matchMedia(this.breakpoint)
  },
  methods: {
    animateElement () {
      const animationValue = this.offset * this.speedFactor * this.directionValue

      if (animationValue >= 0) {
        this.el.style.transform = `translate3d(0, ${animationValue}px ,0)`
      }
    },
    scrollHandler () {
      window.requestAnimationFrame(() => {
        if (this.isInView(this.$refs.parallax) && this.mediaQuery.matches) {
          this.animateElement()
        }
      })
    },
    isInView (el) {
      const rect = el.getBoundingClientRect()
      return (
        rect.bottom >= 0 &&
        rect.top <= (window.innerHeight || document.documentElement.clientHeight)
      )
    }
  }
}
</script>

<style lang="sass">
.parallex-item
  will-change: transform
</style>
