<template lang="pug">
v-col(cols="12" md="9" lg="7" xl="5" :offset-xl="offset" :offset-lg="offset-1" :offset-md="offset-2").my-8.my-lg-12
  v-card(:elevation="0" :class="directionClass").py-4.px-8.content-card
    .content-img.my-n12
      img(:src="image")
    .card-content(:class="[paddingClass, textAlignClass]")
      h5.accent--text.text-h5.mb-4.font-weight-bold {{ heading }}
      p.text-body-1 {{ text }}
</template>

<script>
export default {
  props: {
    flip: {
      type: Boolean,
      default: false
    },
    image: {
      type: String,
      default: ''
    },
    heading: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    }
  },
  computed: {
    offset () {
      return this.flip ? 4 : 3
    },
    directionClass () {
      return `flex-row${this.flip ? '-reverse' : ''}`
    },
    paddingClass () {
      return `p${this.flip ? 'r' : 'l'}-12`
    },
    textAlignClass () {
      return `text-${this.flip ? 'right' : 'left'}`
    }
  }
}
</script>

<style lang="sass" scoped>
.content-card
  background: #1B2871
  color: white
  display: flex
  align-items: center

  @media screen and (max-width: 760px)
    flex-direction: column !important
    padding: 16px !important

    .card-content
      text-align: center !important
      padding: 0 !important

  .content-img
    img
      height: 300px

      @media screen and (min-width: 1264px) and (max-width: 2100px)
      @media screen and (max-width: 960px)
        height: 250px

      @media screen and (min-width: 960px) and (max-width: 1263px)
        height: 200px

    @media screen and (max-width: 760px)
      margin-bottom: 24px !important

      img
        width: 80vw
        max-width: 250px
        height: auto
</style>
